<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="型号名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入型号名称" />
      </a-form-model-item>
      <a-form-model-item label="型号编码" prop="code" >
        <a-input v-model="form.code" placeholder="请输入型号编码" />
      </a-form-model-item>
      <a-form-model-item label="屏幕方向" prop="rotationAngle">
        <a-select v-model="form.rotationAngle" placeholder="请选择" allow-clear>
          <a-select-option v-for="(item,key) in [{name:'横屏',key:0},{name:'竖屏',key:1}]" :key="key" :value="item.key">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="系统模板" prop="systemTemplateId" >
        <a-select v-model="form.systemTemplateId" placeholder="请选择" allow-clear>
          <a-select-option v-for="(item,key) in templateList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
        <span style="font-size: 12px; color: #ff0000;">(设备联网后首次下载的默认模版)</span>
      </a-form-model-item>
      <a-form-model-item label="DIY模板" prop="diyTemplateId" >
        <a-select v-model="form.diyTemplateId" placeholder="请选择" allow-clear>
          <a-select-option v-for="(item,key) in diyTemplateList" :key="key" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
        <span style="font-size: 12px; color: #ff0000;">(设备联网后首次下载的默认模版)</span>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { pageTemplate } from '@/api/valve/template'
import { getDeviceType, addDeviceType, updateDeviceType } from '@/api/valve/deviceType'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      templateList: [],
      diyTemplateList: [],
      // 表单参数
      form: {
        id: null,
        name: null,
        code: null,
        rotationAngle: undefined,
        diyTemplateId: undefined,
        systemTemplateId: undefined,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '型号名称不能为空', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '型号编码不能为空', trigger: 'blur' }
        ],
        rotationAngle: [
          { required: true, message: '请选择屏幕方向', trigger: ['change,blur'] }
        ]
        // systemTemplateId: [
        //   { required: true, message: '系统模板不能为空', trigger: ['change,blur'] }
        // ],
        // diyTemplateId: [
        //   { required: true, message: 'DIY模板不能为空', trigger: ['change,blur'] }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      pageTemplate({ type: 0, pageSize: 999999999, sortField: 'createTime', sortOrder: 'DESCEND' }).then(response => {
        this.templateList = response.data.records
      })
      pageTemplate({ type: 1, pageSize: 999999999, sortField: 'createTime', sortOrder: 'DESCEND' }).then(response => {
        this.diyTemplateList = response.data.records
      })
      this.formType = 1
      this.form = {
        id: null,
        name: null,
        code: null,
        rotationAngle: undefined,
        diyTemplateId: undefined,
        systemTemplateId: undefined,
        status: 0
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDeviceType({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateDeviceType(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDeviceType(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
